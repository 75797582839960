<template>
  <div class='Profile'>
    <div>
      <Top :info="topInfo"/>
      <div class="warp Profile-text">
        <div class="title">
          {{ $t('profileTitle')}}
        </div>
        <div class="content" :style="profileContentStyle">
          {{ $t('profileContent1')}}
        </div>
        <div class="content bottom" :style="profileContentStyle">
          {{ $t('profileContent2')}}
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import profileImg from '@/assets/profile.jpg'
export default {
  name: 'Profile',
  data() {
    return {
      profileContentStyle:this.$t('profileContentStyle'),
      topInfo: {
        background: {
          color: '',
          image: profileImg,
          size: '100%',
          position: '0 -40px',
        },
        text: {
          title: this.$t('profileTopTitle'),
          explain: this.$t('profileTopExplain'),
          style: {
            textAlign: 'left',
            color: '#00479d',
          },
          titleStyle: this.$t("profileTopTitleStyle"),
          explainStyle: this.$t("profileTopExplainStyle"),
        },
      },
    }
  },
  computed: {
     ...mapState({
      lang:state => state.locale
    })
  },
  watch: {
    lang(){
      this.topInfo.text.title = this.$t("profileTopTitle")
      this.topInfo.text.explain = this.$t("profileTopExplain")
      this.topInfo.text.titleStyle = this.$t("profileTopTitleStyle")
      this.topInfo.text.explainStyle = this.$t("profileTopExplainStyle")
      this.profileContentStyle = this.$t('profileContentStyle')
    }
  },
}
</script>
<style lang='less' scoped>
  .Profile {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .Profile-text {
      margin: 100px auto;
      padding: 0 15px;
      .title {
        font-size: 26px;
        line-height: 35px;
        color: #00479d;
        margin-bottom: 15px;
      }
      .content {
        line-height: 25px;
        font-size: 16px;
        margin-top: 10px;
        letter-spacing: 2px;

        .bottom {
          margin-top: 10px;
        }
      }
    }
  }
</style>